.AnimatedT{
    width: 10px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .AnimatedT{
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .AnimatedT{
        width: 100%;
    }
}