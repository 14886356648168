.ButtonLinks {
    height: 100px;
    cursor: pointer;
    opacity: 1.0;
}

.ButtonLinks:hover {
    opacity: 0.6;
}

.ButtonLinks:active {
    opacity: 1.0;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .ButtonLinks{
        height: 75px;
    }
}

@media screen and (max-width: 767px) {
    .ButtonLinks{
        height: 50px;
    }
}