.ButtonLinksImg {
    height: 40%;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .ButtonLinksImg{
        height: 50%;
    }
}

@media screen and (max-width: 767px) {
    .ButtonLinksImg{
        height: 60%;
    }
}