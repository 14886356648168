.AnimatedLogo{
    align-content: center;
    width: 60%;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .AnimatedLogo{
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .AnimatedLogo{
        width: 100%;
    }
}