.Footer {
    text-align: center;
    padding-bottom: 3%;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .Footer{
        font-size: 10%;
    }
}

@media screen and (max-width: 767px) {
    .Footer{
        font-size: 10%;
    }
}