.ButtonBar {
    display: flex;
    justify-content: space-evenly;
    padding-left: 40%;
    padding-right: 40%;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .ButtonBar{
        padding-left: 35%;
        padding-right: 35%;
    }
}

@media screen and (max-width: 767px) {
    .ButtonBar{
        padding-left: 25%;
        padding-right: 25%;
    }
}