.WelcomeButton {
    background: #595959ff;
    border-radius: 8px;

    font-family: Varela Round, serif;
    font-weight: bold;
    font-size: 80%;
    line-height: 29px;
    align-items: center;
    text-align: center;
    letter-spacing: 2px;

    color: #FFFFFF;

    border:0px;
    outline:none;
    cursor: pointer;

    width: 241px;
    height: 70px;
}

.WelcomeButton:hover {
    background: #e69138ff;
}

.WelcomeButton:active {
    background: rgb(182, 110, 33);
}