.Heart {
    color: #e06666ff;
}

.Heart:hover {
    color: #93c47dff;
}

.Heart:active {
    color: #595959ff;
}